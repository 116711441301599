import styled from 'styled-components';

/**
 * Componente de estilo para un poema.
 */
const Poema = styled.article`

  width: 320px;
  margin: auto;

  h2 {
    font-family: var(--ff-primary);
    font-size: 24px;
    font-weight: 700;
    color: var(--clr-principal);
  }

  pre {
    font-family: var(--ff-poem);
    font-size: 20px;
    font-weight: 400;
    font-style: italic;
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  button {
    width: 100%;
    margin: 0.5rem auto;
  }

  @media (max-width: 440px) {
      h2 {
        font-size: 20px;
      }
      pre {
        font-size: 16px;
      }
    }

`;

export default Poema;