import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import Boton from '../Boton';

/**
 * Componente para la barra de botones de debajo de las poesías
 * (con los enlaces para "anterior", "volver" y "siguiente").
 */
const LinkBar = () => {
    // Renderizado
    return (
        <LinkBarContainer>
            <Boton>Anterior</Boton>
            <Boton onClick={() => navigate(-1)}>Volver</Boton>
            <Boton>Siguiente</Boton>
        </LinkBarContainer>
    );
}

// Componente para darle estilo
const LinkBarContainer = styled.nav`
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;

    button {
        font-weight: 400;
        color: var(--clr-grey-4);
    }
`;

export default LinkBar;
