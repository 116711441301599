import styled from 'styled-components';

/**
 * Componente para incluir un adorno a las páginas.
 * Sólo se ocupa del centrado y del estilo.
 */
const Adorno = styled.div`
    position: fixed;
    width: 40%;
    height: 40%;
    /*max-height: 400px;*/
    overflow: hidden;
    z-index: 0;
    opacity: 0.3;

    @media (min-width: 440px) {
        left: 0px;
        bottom: 0px;
    }

    @media (max-width: 440px) {
        /*transform: translateY(-30%) rotate(270deg) scaleY(-1);*/
        bottom: 0px;
        width: 70%;
    }

`;

export default Adorno;