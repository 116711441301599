import styled from 'styled-components';

/**
 * Componente para formatear la fecha en una poesía.
 */
const Fecha = styled.div`
    color: var(--clr-principal);
    text-align: right;
    font-size: 14px;
    font-weight: 200;
`;

export default Fecha;
