import React from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react'
import Image from 'gatsby-image';

import Layout from '../components/Layout/Layout';
import LinkBar from '../components/LinkBar/LinkBar';
import Adorno from '../components/Adorno';
import Fecha from '../components/Fecha';
import Poema from '../components/Poema';

/**
 * Plantilla para generar la página de cada poema.
 * 
 * Esta plantilla recibe los datos de cada poema y genera su página
 * correspondiente.
 */
const PlantillaPoema = ({ data }) => {
  // Extraemos los datos
  const { frontmatter: post, body } = data.mdx;

  // Renderización
  return (
    <Layout>
      <Poema>
        <h2>{post.title}</h2>
        <MDXProvider components={components}>
          <MDXRenderer>{body}</MDXRenderer>
        </MDXProvider>
        <Fecha>{post.fecha}</Fecha>
      </Poema>
      <LinkBar />
      <Adorno>
        <Image fluid={post.image.childImageSharp.fluid} alt="Adorno para el poema" />
      </Adorno>
    </Layout>
  );
}

// Consulta GraphQL para obtener la lista de entradas
export const poemsQuery = graphql`
query GetPoemsBySlug($slug: String) {
    mdx(frontmatter: {slug: {eq: $slug}}) {
      frontmatter {
        title
        slug
        category
        date
        fecha
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      body
    }
  }`;


// Componente para sustituir los párrafos por "pre"
const Parrafo = (props) => <pre {...props} />;

const components = {
  p: Parrafo
}

export default PlantillaPoema;